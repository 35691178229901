import React from "react";
import { AuthService } from "./auth.service";

export const useAuth = () => {
  const authService = AuthService.getInstance();
  return {
    signIn: authService.signIn.bind(authService),
    signOut: authService.signOut.bind(authService),
    isAuthenticated: authService.isAuthenticated.bind(authService),
    getUserProfile: authService.getUserProfile.bind(authService),
    setUserProfile: authService.setUserProfile.bind(authService),
    getAuthToken: authService.getAuthToken.bind(authService),
    setAuthToken: authService.setAuthToken.bind(authService),
    getActor: authService.getActor.bind(authService),
    setActor: authService.setActor.bind(authService),
    removeUserProfile: authService.removeUserProfile.bind(authService),
    removeActor: authService.removeActor.bind(authService),
    removeAuthToken: authService.removeAuthToken.bind(authService),
  };
};
