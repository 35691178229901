import { Asset } from "expo-asset";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import React, { ReactElement, useEffect, useState } from "react";

export type FontSource = Parameters<typeof Font.loadAsync>[0];
const usePromiseAll = (promises: Promise<void | void[]>[], cb: () => void) =>
  useEffect(() => {
    (async () => {
      await Promise.all(promises);
      cb();
    })();
  });

const useLoadAssets = (assets: number[], fonts: FontSource): boolean => {
  const [ready, setReady] = useState(false);
  usePromiseAll(
    //@ts-ignore
    [Font.loadAsync(fonts), ...assets.map((asset) => Asset.loadAsync(asset))],
    () => setReady(true)
  );
  return ready;
};

interface LoadAssetsProps {
  fonts?: FontSource;
  assets?: number[];
  performRingLevelUp?(): Promise<void>;
  children: ReactElement | ReactElement[];
}

export const LoadAssetsV = ({
  assets,
  fonts,
  performRingLevelUp,
  children,
}: LoadAssetsProps) => {
  const [appisReady, setAppisReady] = useState<boolean>(false);
  useEffect(() => {
    // Prevent native splash screen from autohiding
    try {
      SplashScreen.preventAutoHideAsync().then(() => {
        prepareResources();
      });
    } catch (e) {
      console.warn(e);
    }
  }, []);

  const prepareResources = async () => {
    await downloadAssets();
    await downloadFonts();
    await prepareRingLevelUp();

    setAppisReady(true);
    await SplashScreen.hideAsync();
  };

  const downloadAssets = async () => {
    if (assets && Array.isArray(assets)) {
      assets.map(async (asset) => {
        await Asset.loadAsync(asset);
      });
    }
  };

  const downloadFonts = async () => {
    if (fonts) {
      await Font.loadAsync(fonts);
    }
  };

  const prepareRingLevelUp = async () => {
    if (performRingLevelUp) {
      await performRingLevelUp();
    }
  };

  if (!appisReady) return null;

  return <>{children}</>;
};
