import { ViewStyle, TextStyle } from "react-native";

export type StyleProps = {
  style?: ViewStyle;
};

export type Typography = {
  fontFamily?: string;
  fontSize: number;
  lineHeight: number;
  color?: string;
};

export type Typographies = {
  body: Typography;
  callout: Typography;
  caption: Typography;
  footnote: Typography;
  headline: Typography;
  subhead: Typography;
  title1: Typography;
  title2: Typography;
  title3: Typography;
  certificateTitle: Typography;
  certificateBody: Typography;
  iconSize: Typography;
  buttonTitle: Typography;
  title: Typography;
  subTitle: Typography;
};

export type Spacing = {
  tiny: number;
  small: number;
  base: number;
  large: number;
  xLarge: number;
};

export type Palette = {
  black: string;
  darkGray: string;
  gray: string;
  lightGray: string;
  white: "white";
  transparent: "transparent";
};

export type Shadow = {
  shadowColor: string;
  shadowOffset: { width: number; height: number };
  shadowOpacity: number;
  shadowRadius: number;
  backgroundColor: string;
};

export type Styles = {
  barHeight: {
    height: number;
  };
  shadow: Shadow;
  borderRadius: {
    borderRadius: number;
  };
  separator: {
    borderBottomWidth: number;
    borderColor: string;
  };
  button: {
    height: number;
    justifyContent: "center";
    alignItems: "center";
    flexDirection: "row";
    padding: number;
    marginBottom: number;
    borderRadius: number;
  };
  buttonIcon: {
    marginRight: number;
  };
};

export type StyleGuide = {
  palette: Palette;
  typography: Typographies;
  spacing: Spacing;
  styles: Styles;
};

const darkGray = "#999999";
const gray = "#CCCCCC";
const tiny = 8;
const small = 16;
const borderRadius = tiny;
const shadow = {
  shadowColor: "#000000",
  shadowOffset: { width: 0, height: 0 },
  shadowOpacity: 0.18,
  shadowRadius: 2,
  backgroundColor: "white",
};

export const styleGuide: StyleGuide = {
  palette: {
    black: "#000000",
    white: "white",
    transparent: "transparent",
    darkGray,
    gray,
    lightGray: "#F3F3F3",
  },
  typography: {
    body: {
      fontSize: 14,
      lineHeight: 20,
      fontFamily: '"Muli",sans-serif;',
    },
    callout: {
      fontSize: 17,
      lineHeight: 20,
      fontFamily: '"Muli",sans-serif;',
    },
    title: {
      fontSize: 16,
      lineHeight: 20,
      fontFamily: '"Muli",sans-serif;',
    },
    subTitle: {
      fontSize: 14,
      lineHeight: 20,
      fontFamily: '"Muli",sans-serif;',
    },
    caption: {
      fontSize: 11,
      lineHeight: 13,
      fontFamily: '"Muli",sans-serif;',
    },
    footnote: {
      fontSize: 13,
      lineHeight: 18,
      fontFamily: '"Muli",sans-serif;',
      color: darkGray,
    },
    headline: {
      fontSize: 20,
      lineHeight: 22,
      fontFamily: '"Muli",sans-serif;',
    },
    subhead: {
      fontSize: 20,
      lineHeight: 28,
      fontFamily: '"Muli",sans-serif;',
    },
    buttonTitle: {
      fontSize: 15,
      lineHeight: 20,
      fontFamily: '"Muli",sans-serif;',
    },
    title1: {
      fontSize: 34,
      lineHeight: 41,
      fontFamily: '"Muli",sans-serif;',
    },
    title2: {
      fontSize: 28,
      lineHeight: 34,
      fontFamily: '"Muli",sans-serif;',
    },
    title3: {
      fontSize: 26,
      lineHeight: 26,
      fontFamily: '"Muli",sans-serif;',
    },
    certificateTitle: {
      fontSize: 32,
      lineHeight: 34,
      // fontFamily: 'itce-dscr',
    },
    certificateBody: {
      fontSize: 12,
      lineHeight: 14,
      // fontFamily: 'times-regular',
    },
    iconSize: {
      fontSize: 100,
      lineHeight: 14,
      // fontFamily: 'times-regular',
    },
  },
  spacing: {
    tiny,
    small,
    base: 24,
    large: 48,
    xLarge: 64,
  },
  styles: {
    barHeight: {
      height: 45,
    },
    shadow,
    borderRadius: {
      borderRadius,
    },
    separator: {
      borderBottomWidth: 1,
      borderColor: "#ebebeb",
    },
    button: {
      height: 45,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      marginBottom: small,
      padding: tiny,
      borderRadius,
    },
    buttonIcon: {
      marginRight: tiny,
    },
  },
};
