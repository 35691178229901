//@ts-nocheck
import React, { createContext } from "react";

interface IContextProps {
  state: IState;
  dispatch: ({ type }: { type: string }) => void;
}

const ViewportContext = createContext({} as IContextProps);

export const ViewportProvider = ({ children }: { children: any }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const getBreakPoint = (width: number): "xs" | "sm" | "md" | "lg" | "xl" => {
    if (width >= 1920) return "xl";
    if (width >= 1280) return "lg";
    if (width >= 960) return "md";
    if (width >= 600) return "sm";
    return "xs";
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  const value = { width, height, bp: getBreakPoint(width) };
  return (
    <ViewportContext.Provider value={value}>
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = () => {
  const { width, height, bp }: any = React.useContext(ViewportContext);
  return { width, height, bp };
};
