import { RingLevelService } from "./message-handler.service";

export interface Message {}

export interface MessageResponse {}

export interface MessageHandler {
  setNext(messageHandler: MessageHandler): MessageHandler;
  handle: (
    message: Message,
    service: RingLevelService,
    response: MessageResponse
  ) => Promise<void>;
}

export const unsupportedMessageTypeError = "Unsupported message type";

export abstract class AbstractMessageHandler implements MessageHandler {
  public res: any;
  public nextMessageHandler?: MessageHandler;

  public setNext(messageHandler: MessageHandler): MessageHandler {
    this.nextMessageHandler = messageHandler;
    return messageHandler;
  }

  public async handle(
    message: Message,
    service: RingLevelService,
    response: MessageResponse
  ): Promise<void> {
    if (this.nextMessageHandler) {
      return this.nextMessageHandler.handle(message, service, response);
    }
    return;
  }
}
