//@ts-ignore
import {
  AbstractMessageHandler,
  Message,
  MessageResponse,
} from "../abstract-message-handler";
import { RingLevelService } from "../message-handler.service";

const is = require("is_js");

export class RestServerServiceHandler extends AbstractMessageHandler {
  name = "rest-server-service-ring-level";
  async handle(
    message: Message,
    service: RingLevelService,
    response: MessageResponse
  ) {
    try {
    } catch (e) {
      console.log("error in Rest Server Service Init", e);
    }
    return super.handle(message, service, response);
  }
}
