import { Assets } from "@react-navigation/stack";
import React, { Suspense, lazy } from "react";

const NetworkOperatorScreen = lazy(
  () => import("../../../screens/NetworkOperator")
);
const OrganisationScreen = lazy(() => import("../../../screens/Organization"));
const AnalyticsScreen = lazy(() => import("../../../screens/Analytics"));
const ProductScreen = lazy(() => import("../../../screens/Products"));

const DraftAssetScreen = lazy(() => import("../../../screens/DraftAssets"));

const DraftDetailScreen = lazy(() => import("../../../screens/DraftDetail"));
const ListedAssetDetailScreen = lazy(
  () => import("../../../screens/ListedAssetDetail")
);

const AssetTableViewScreen = lazy(
  () => import("../../../screens/AssetTabelView")
);

const CreateCollectableScreen = lazy(
  () => import("../../../screens/CreateCollectables")
);

const CollectiblesFormScreen = lazy(() => import("../../../screens/NewAssets"));

const SoldAssetsScreen = lazy(() => import("../../../screens/SoldAssets"));

const SoldAssetDetailScreen = lazy(
  () => import("../../../screens/SoldAssetsDetail")
);

const AssetsDescriptionScreen = lazy(
  () => import("../../../screens/AssetsDescription")
);

const SoldTableViewScreen = lazy(
  () => import("../../../screens/SoldAssetsTable")
);

const UploadAssetsScreen = lazy(() => import("../../../screens/UploadAssets"));

const DraftAssetTableScreen = lazy(
  () => import("../../../screens/DraftAssetsTable")
);

const DraftAssetsDescriptionScreen = lazy(
  () => import("../../../screens/DraftAssetsDetail")
);

export const routes = [
  { path: "/organization", Component: OrganisationScreen },
  { path: "/analytics", Component: AnalyticsScreen },
  { path: "/assets-list", Component: ProductScreen },
  { path: "/new-assets", Component: CollectiblesFormScreen },
  { path: "/assets-list/detail", Component: ListedAssetDetailScreen },
  { path: "/create-collectibles", Component: CreateCollectableScreen },
  { path: "/sold-assets", Component: SoldAssetsScreen },
  { path: "/sold-assets/detail", Component: SoldAssetDetailScreen },
  { path: "/table-view", Component: AssetTableViewScreen },
  { path: "/asset-description", Component: AssetsDescriptionScreen },
  { path: "/sold-table-view", Component: SoldTableViewScreen },
  { path: "/upload-assets", Component: UploadAssetsScreen },
  { path: "/draft-assets", Component: DraftAssetScreen },
  { path: "/draft-assets/detail", Component: DraftDetailScreen },
  { path: "/draft-assets-table", Component: DraftAssetTableScreen },
  { path: "/draft-asset-description", Component: DraftAssetsDescriptionScreen },
];
