export class EventBusV {
  protected static subscriptions: any = {};

  on(eventType: string, callback: (args: any) => any) {
    const id = Symbol("id");
    if (!EventBusV.subscriptions[eventType])
      EventBusV.subscriptions[eventType] = {};
    EventBusV.subscriptions[eventType][id] = callback;
    return {
      off: function unsubscribe() {
        delete EventBusV.subscriptions[eventType][id];
        if (
          Object.getOwnPropertySymbols(EventBusV.subscriptions[eventType])
            .length === 0
        ) {
          delete EventBusV.subscriptions[eventType];
        }
      },
    };
  }

  emit(eventType: any, arg: any) {
    if (!EventBusV.subscriptions[eventType]) return;

    Object.getOwnPropertySymbols(
      EventBusV.subscriptions[eventType]
    ).forEach((key) => EventBusV.subscriptions[eventType][key](arg));
  }
}

export const EventBus = new EventBusV();
