//@ts-nocheck
import React, { useEffect } from "react";
import { MachineConfig, EventObject, actions, assign } from "xstate";
import { useQuery } from "@apollo/react-hooks";
import { useMachine } from "../../providers/machine";
import { GraphqlServer } from "../../../../servers/graphql.server";
import config from "../../config/default-blueprint.json";
import { GET_NAV_ITEMS } from "../../appGraphql/queries";
import { humanize } from "uno-js";
// import { PRODUCT, PROVENANCE } from "../../config/data";

export const GetNavitemContext = React.createContext();
export let getNavitemMachine: any;

export type EventId = "FETCH" | "RETRY";
export interface GetNavitemEvent extends EventObject {
  type: EventId;
}
export interface GetNavitemStateSchema {
  states: {
    idle: {};
    loading: {};
    success: {};
    failure: {};
  };
}
export type AvailableStates = keyof GetNavitemStateSchema["states"];

const getNavitem = async (context) => {
  //Remote Request
  const client = GraphqlServer?.getInstance()?.client;

  // if (client.query) {
  //   //GraphQL here
  //   client.query({query:GET_ORGANIZATION}).then(sol =>{

  //     return new Promise(async (resolve, reject) => {
  //       setTimeout(() => {
  //         resolve("Success");

  //         reject("Product not found");
  //       }, 4000);
  //     });
  //   }
  //   )
  // }

  const getMeta = (datas) => {
    if (datas) {
      let _output: any = [];

      for (const [key, value] of Object.entries(datas)) {
        if (
          key === "fullAddress" ||
          key === "email" ||
          key === "phoneNo" ||
          key === "website" ||
          key === "owner"
        ) {
          _output.push({
            key: humanize(key.toString()),
            value: value,
          });
        }
      }
      return _output;
    }
  };

  if (client.query) {
    return new Promise((resolve, reject) => {
      client.query({ query: GET_NAV_ITEMS }).then((res) => {
        resolve({
          // organizationData: res?.data?.getMyOrganization,
          // img: res?.data?.getMyOrganization?.logo,
          // title: res?.data?.getMyOrganization?.name,
          // description: res?.data?.getMyOrganization?.description,
          // key: res?.data?.getMyOrganization?.fullAddress,
          // meta: getMeta(res?.data?.getMyOrganization),
          navItems: res?.data?.getFENavConfig?.navItems,
        });
      });
    }).catch((error) => {
      //error
    });
  }
};

const loadingAction = assign((context) => ({
  payload: null,
}));
const failureAction = assign((context, event) => ({
  payload: event?.data,
}));
const successAction = assign((context, event) => ({
  payload: event?.data,
}));

const idleAction = assign((context, event) => ({
  payload: null,
}));

export const useGetNavitem = (_context: any) => {
  useEffect(() => {
    if (getNavitemMachine) {
      getNavitemMachine.send({ type: "FETCH" });
    }
  }, []);
  const _machineConfig = {
    id: "io.trag.getNavitem",
    initial: "idle",
    context: {
      ..._context,
      payload: undefined,
    },
    states: {
      idle: {
        on: {
          FETCH: { target: "loading", actions: [loadingAction] },
        },
      },
      loading: {
        invoke: {
          id: "getNavitem",
          src: (context, event) => getNavitem(context),
          onDone: {
            target: "success",
            actions: [successAction],
          },
          onError: {
            target: "failure",
            actions: [failureAction],
          },
        },
      },
      success: {
        type: "final",
      },
      failure: {
        on: {
          RETRY: { target: "loading" },
        },
        type: "final",
      },
    },
  };
  getNavitemMachine = useMachine(_machineConfig);

  return {
    getNavitemMachine,
    state: getNavitemMachine?.state,
    context: getNavitemMachine?.context,
  };
};
