import gql from "graphql-tag";

export const GET_NAV_ITEMS = gql`
  query GetSideNavItems {
    getFENavConfig {
      navItems {
        key
        label
        enable
      }
      features
    }
  }
`;
