//@ts-nocheck
//CUSTOMIZER
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";
export const ADD_TRANSPlAYOUT = "ADD_TRANSPlAYOUT";
export const ADD_SIDEBAR_BACKGROUND = "ADD_SIDEBAR_BACKGROUND";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";
