//@ts-ignore
import { DataSet } from "vis-data";
import AsyncStorage from "@react-native-community/async-storage";
export const AUTH_TOKEN = "@vlinder:auth-token";
export const ACTOR = "@vlinder:actor";
export const USER_PROFILE = "@vlinder:user-profile";

export class AuthService {
  private static instance: AuthService;
  authTokenDS = new DataSet({
    fieldId: "id",
  });
  userProfileDS = new DataSet({
    fieldId: "id",
  });
  actorDS = new DataSet({
    fieldId: "id",
  });
  graphQLClientDS = new DataSet({
    fieldId: "id",
  });
  fieldDataDS = new DataSet({
    fieldId: "id",
  });
  coverageDS = new DataSet({
    fieldId: "id",
  });
  premiumDS = new DataSet({
    fieldId: "id",
  });
  activeCategoryDS = new DataSet({
    fieldId: "id",
  });
  premiumPayoutDS = new DataSet({
    fieldId: "id",
  });
  coveragePayoutDS = new DataSet({
    fieldId: "id",
  });
  private constructor() {}
  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }
  async init() {
    await this.getAuthToken();
    await this.getActor();
    await this.getUserProfile();
  }

  async setAuthToken(token: string) {
    try {
      await AsyncStorage.setItem(AUTH_TOKEN, token);
      const _authTokenDS = {
        id: AUTH_TOKEN,
        value: token,
      };
      this.authTokenDS.update(_authTokenDS);
    } catch (e) {
      // saving error
    }
  }
  async setActor(actor: string) {
    try {
      await AsyncStorage.setItem(ACTOR, actor);
      const _actorDS = {
        id: ACTOR,
        value: actor,
      };
      this.actorDS.update(_actorDS);
    } catch (e) {
      // saving error
    }
  }
  async setUserProfile(userProfile: any) {
    try {
      let _userProfile = userProfile;
      if (userProfile && typeof userProfile !== "string") {
        _userProfile = JSON.stringify(userProfile);
      }
      await AsyncStorage.setItem(USER_PROFILE, _userProfile);
      const _userProfileDS = {
        id: USER_PROFILE,
        value: userProfile,
      };
      this.userProfileDS.update(_userProfileDS);
    } catch (e) {
      // saving error
    }
  }

  async getActor() {
    try {
      const value = await AsyncStorage.getItem(ACTOR);
      if (value !== null) {
        // value previously stored
        const _actorDS = {
          id: ACTOR,
          value: value,
        };
        this.actorDS.update(_actorDS);
        return value;
      } else {
        const _value = this.actorDS.get();
        let temp = _value.find((item) => {
          //@ts-ignore
          return item?.value;
        });
        //@ts-ignore
        return temp?.value;
      }
    } catch (e) {
      // error reading value
    }
  }
  async getUserProfile() {
    try {
      const value = await AsyncStorage.getItem(USER_PROFILE);
      if (value !== null) {
        // value previously stored
        const _userProfileDS = {
          id: USER_PROFILE,
          value: JSON.parse(value),
        };
        this.userProfileDS.update(_userProfileDS);
        return JSON.parse(value);
      }
    } catch (e) {
      // error reading value
    }
  }
  async getAuthToken() {
    try {
      const value = await AsyncStorage.getItem(AUTH_TOKEN);
      if (value !== null) {
        // value previously stored
        const _authTokenDS = {
          id: AUTH_TOKEN,
          value: value,
        };
        this.authTokenDS.update(_authTokenDS);
        return value;
      }
    } catch (e) {
      // error reading value
    }
  }

  async removeAuthToken() {
    try {
      this.authTokenDS.clear();
      await AsyncStorage.removeItem(AUTH_TOKEN);
    } catch (e) {
      // remove error
    }
  }
  async removeActor() {
    try {
      this.actorDS.clear();
      await AsyncStorage.removeItem(ACTOR);
    } catch (e) {
      // remove error
    }
  }
  async removeUserProfile() {
    try {
      this.userProfileDS.clear();
      await AsyncStorage.removeItem(USER_PROFILE);
    } catch (e) {
      // remove error
    }
  }
  async signIn({
    newToken,
    rememberMe = false,
    actorName,
    userProfile,
  }: {
    newToken: string;
    rememberMe?: boolean;
    actorName?: string;
    userProfile?: any;
  }) {
    if (rememberMe) {
      if (newToken) {
        await this.setAuthToken(newToken);
      }
      if (actorName) {
        await this.setActor(actorName);
      }
      if (userProfile) {
        await this.setUserProfile(userProfile);
      }
    } else {
      if (newToken) {
        const _authTokenDS = {
          id: AUTH_TOKEN,
          value: newToken,
        };
        this.authTokenDS.update(_authTokenDS);
      }
      if (actorName) {
        const _actorDS = {
          id: ACTOR,
          value: actorName,
        };
        this.actorDS.update(_actorDS);
      }
      if (userProfile) {
        await this.setUserProfile(userProfile);
      }
    }
  }
  async signOut() {
    await this.removeAuthToken();
    await this.removeActor();
    await this.removeUserProfile();
  }
  isAuthenticated() {
    const token = this.authTokenDS.get(AUTH_TOKEN);
    if (token) {
      return true;
    }
    return false;
  }
}
