import { Context } from "@loopback/context";
import { Application } from "@loopback/core";
export class VlinderApp extends Application {
  static readonly subscriptions: any = {};
  static readonly ctx = new Context("vlinder");

  constructor() {
    super();
  }

  bindToValue(key: string, value: any) {
    VlinderApp.ctx.bind(key).to(value);
    VlinderApp.subscriptions[key] = value;
  }

  unbindValue(key: string) {
    if (!VlinderApp.subscriptions[key]) return;

    VlinderApp.ctx.unbind(key);
    delete VlinderApp.subscriptions[key];
  }

  getValue(key: string): any {
    if (!VlinderApp.subscriptions[key]) return;

    return VlinderApp.ctx.getSync(key);
  }
}
